/* ======= CONTACTBOX FORM ======= */

$form_bg: transparent;
$form_div_width: calc(50% - 1.5rem);
$form_label_width: 100%;
$form_label_color: $form-label-color;
$form_label_fontsize: $form-label-font-size;
$form_input_color: $input-color;
$form_input_bg: $input-bg;
$form_input_border: $input-border-width solid $input-border-color;
$form_input_width: 100%;
$form_input_padding: $input-padding-y $input-padding-x;
$form_input_fontsize: $input-font-size;
$form_input_color_focus: $input-focus-color;
$form_input_border_focus: $input-border-width solid $input-focus-border-color;
$form_input_bg_focus: $input-focus-bg;
$form_privacy_fontsize: 80%; 
$form_invia_bg: $primary;
$form_invia_color: #FFF;
$form_invia_bg_hover: if($form_invia_color == $color-contrast-light, shade-color($form_invia_bg, $btn-hover-bg-shade-amount), tint-color($form_invia_bg, $btn-hover-bg-tint-amount));
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: $btn-border-width solid $form_invia_bg;
$form_invia_fontsize: $btn-font-size-lg;
$form_error_color: $warning;



#nomesia_form {
	padding: 1rem 0;
	margin: 1rem 0;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none;
	}
	div {
		display: inline-block;
		margin: .5rem;
		width: calc(100% - 1.25rem);
		&#messaggio_container,
		&#marketing_container,
		&#privacy_container,
		&#more_info_container,
		&#submit_container,
		&#ragione_sociale_container {
			width: calc(100% - 1.5rem);
		}
		&#submit_container {
			margin: 0 auto;
		}
		@include media-breakpoint-up(sm) {
			width: $form_div_width;
		}
	}
	label {
		color: $form_label_color;
		font-size: $form_label_fontsize; 
		display: inline-block;
		margin-bottom: $form-label-margin-bottom;
		text-transform: uppercase;
		a {
			color: #FFF;
			font-weight: 700;
		}
		&.error {
			display: inline-block;
			font-size: 80%;
			margin-bottom: 0;
			padding: 7px 5px 0 5px;
			color: $form_error_color;
		}
	}
	textarea,
	input,
	select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		display: block;
		transition: $transition-base;
		border-radius: 0;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;
		}
	}
	select {
		width: $form_input_width;
	}
	textarea {
		height: 7rem;
	}
	#privacy_container,
	#marketing_container {
		margin: 0 0 .5rem;
		min-height: 1.25rem;
		label {
			font-weight: normal;
			display: inline-block;
			width: calc(100% - 1.4rem);
			font-size: $form_privacy_fontsize;
			line-height: 1.2;
			text-transform: none;
			&.error {
				margin-left: 1rem;
			}
		}
		input {
			width: 1rem;
			margin: 0.2rem .35rem 0 0;
			padding: 0;
			float: left;
			&:focus	{
				box-shadow: none;
			}
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: bold;
		text-transform: uppercase;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: .5rem 1rem;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		cursor: pointer;
		white-space: normal;
		&:hover,
		&:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none !important;
	}
	.field_status {
		margin: 0;
		padding: 0;
		width: 100%;
		display: block;
		&.error{
			display: block;
		}
	}
}