/* ======= BOOTSTRAP (5) ======= */

$enable-rounded: false;
$primary: #238BCC;
$secondary: #15467C;
$light: #F1F1F2;
$body-color: #212529;
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;
$headings-color: $secondary;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.55;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$h6-font-size: $font-size-base;
$btn-font-family: $font-family-sans-serif;
$btn-font-weight: 700;
$card-bg: transparent;
$card-border-color: transparent;
$card-title-color: $secondary;


@import '../../../node_modules/bootstrap/scss/bootstrap';