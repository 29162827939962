/* ====================  IMPORT  ==================== */

// BOOTSTRAP
@import 'bootstrap';

// FONTAWESOME (optional, remove if not used + remove in package.json)
@import 'fontawesome';

// CONTACTBOX FORM (remove if not used)
@import 'cb-form';

// OWL CAROUSEL
@import '../../../node_modules/owl.carousel/src/scss/owl.carousel';
@import '../../../node_modules/owl.carousel/src/scss/owl.theme.default';


/* ====================  FONTS  ==================== */


/* open-sans-regular - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/open-sans-v36-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v36-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 400;
	src: url('./fonts/open-sans-v36-latin-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v36-latin-italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('./fonts/open-sans-v36-latin-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v36-latin-700.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* open-sans-700italic - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	src: url('./fonts/open-sans-v36-latin-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('./fonts/open-sans-v36-latin-700italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}




/* ====================  CUSTOM  ==================== */



@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

.bg-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.mh-img {
	min-height: 15rem;
	&-big {
		min-height: 25rem;
	}
}

.divider {
	height: 3px;
	width: 70px;
	margin-top: 1.25rem;
	margin-bottom: 1.5rem;
	background: #60BEEC;
}

.btn {
	text-transform: uppercase;
	&-primary {
		color: #FFF !important;
	}
}


// Back to top

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	border: 1px solid #eee;
	border-radius: 0;
	background: $secondary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
	display: none;
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}

// Owl

.owl-theme {
	.owl-nav {
		[class*=owl-] {
			margin: 0;
			&:hover {
				background: transparent;
			}
		}
		.owl-prev,
		.owl-next {
			width: 14px;
			height: 27px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: block !important;
			border: 0 none;
			&:hover {
				i {
					color: $secondary;
				}
			}
			i {
				color: $secondary;
				transition: $transition-base;
			}
		}
		.owl-prev {
			left: -14px;
		}
		.owl-next {
			right: -14px;
		}
	}
}